import { Redirect } from 'react-router-dom';
import { PlanTypes } from '../../../../generated/graphql';
import url from '../../../../constants/url';
import { useMe } from '../../../../lib/use-me';

function HomeRedirect() {
  const me = useMe();
  return me?.company?.planType === PlanTypes.Ppo3 ? (
    <Redirect from={url.home} to={url.ppo3.index} />
  ) : (
    <Redirect from={url.home} to={url.selfManagement.index} />
  );
}

export default HomeRedirect;
