import { FC } from 'react';
import Loader from '../../atoms/Loader';

interface LoaderProps {
  size?: 'tiny' | 'small' | 'normal' | 'large';
  color?: string;
  withMargin?: boolean;
}

const ComponentLoader: FC<LoaderProps> = ({ size = 'tiny', withMargin = true }) => {
  return (
    <div className="p-5 text-center">
      <Loader size={size} withMargin={withMargin} />
    </div>
  );
};

export { ContainerLoading } from './common';

export default ComponentLoader;
