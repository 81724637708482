import './polyfills';

import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';

import Root from './components/pages/App/Root';
import * as serviceWorker from './serviceWorker';
import { history } from './lib/use-router';
import './lib/i18n';
import { HostProvider } from './components/contexts/HostContext';
import LangProvider from './components/contexts/LangContext';
import ThemeProvider from './components/contexts/ThemeContext';
import Auth0Provider from './components/organisms/Auth0Provider';
import ApolloProvider from './components/organisms/ApolloProvider';
import { ResetStyle, GlobalStyle, RootStyle } from './components/pages/App/styled';
import HubspotChatProvider from './components/contexts/HubspotChatContext';

import './assets/css/generated.css';

const App = () => {
  return (
    <LangProvider>
      <ResetStyle />
      <RootStyle />
      <GlobalStyle />
      <HostProvider>
        <Auth0Provider>
          <ApolloProvider>
            <Router history={history}>
              <Root />
            </Router>
          </ApolloProvider>
        </Auth0Provider>
      </HostProvider>
    </LangProvider>
  );
};
ReactDOM.render(
  <ThemeProvider>
    <HubspotChatProvider>
      <App />
    </HubspotChatProvider>
  </ThemeProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
