import { FC, ReactNode, Suspense, lazy, useEffect } from 'react';

import { AuthProvider } from '../../../contexts/AuthContext';
import LoadingPage from '../Loading';
import { useRouter } from '../../../../lib/use-router';
import { useAuth0User } from '../../../../lib/use-auth0-user';
import { AUTH_TOKEN_EVENT, getToken } from '../../../../lib/auth-token';
import url from '../../../../constants/url';
import { useMe } from '../../../../lib/use-me';
import { getCurrentLangFromLocaleStorage, useSetLanguage } from '../../../../lib/use-language';
import { buildPathAndQuery } from '../../../../utils/url';

const InitializeSass = lazy(() => import('./InitializeSass'));

interface MemberOnlyContainerProps {
  children?: ReactNode;
}
const MemberOnlyContainer: FC<MemberOnlyContainerProps> = ({ children }) => {
  const { query, replace, location } = useRouter();
  const me = useMe();

  const setLang = useSetLanguage();
  useEffect(() => {
    if (!me) return;
    const currentLang = getCurrentLangFromLocaleStorage(me.id, query);
    if (currentLang) {
      setLang(currentLang, me.id);
      if (query.lang) {
        replace(buildPathAndQuery({ location, removing: ['lang'] }));
      }
    }
  }, [me, setLang, query, location, replace]);

  return (
    <>
      <Suspense fallback="">
        <InitializeSass me={me} />
      </Suspense>
      {children}
    </>
  );
};

interface MemberOnlyProps {
  children?: ReactNode;
}
const MemberOnly: FC<MemberOnlyProps> = ({ children }) => {
  const { replace } = useRouter();
  const { isAuthenticated, isLoading, user } = useAuth0User();
  // const { loginWithRedirect } = useAuth0();

  const token: string | void | null = getToken();

  useEffect(() => {
    document.addEventListener(AUTH_TOKEN_EVENT, () => {
      const token = getToken();
      if (!isLoading && (!isAuthenticated || !user) && !token) {
        replace(url.login.index);
      }
    });

    return document.removeEventListener(AUTH_TOKEN_EVENT, () => {
      return;
    });
  }, [replace, isLoading, isAuthenticated, user]);

  if (isLoading) {
    return <LoadingPage />;
  }

  // v6.5
  // if (!isLoading && (!isAuthenticated || !user)) {
  //   loginWithRedirect({ appState: { returnTo: location.pathname } });
  //   return null;
  // }

  if (!isLoading && (!isAuthenticated || !user) && !token) {
    replace(url.login.index);
    return null;
  }

  return (
    <AuthProvider redirect isLoading={isLoading} isAuthenticated={isAuthenticated} user={user!}>
      <MemberOnlyContainer>{children}</MemberOnlyContainer>
    </AuthProvider>
  );
};

export default MemberOnly;
