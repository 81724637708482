import { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { AuthContext } from '../components/contexts/AuthContext';
import {
  Period,
  Team,
  ScopedRole,
  ScopeSourceTypes,
  UserScopedRoleKeys,
  GetMeCompanyTeams,
  GetMeCompanyTeamsQuery,
  GetMeCompanyTeamsQueryVariables,
  GetMeCompanyBilling,
  GetMeCompanyBillingQuery,
  GetMeCompanyBillingQueryVariables,
  GetMeCompanySettings,
  GetMeCompanySettingsQuery,
  GetMeCompanySettingsQueryVariables,
  GetMeCompanyGroups,
  GetMeCompanyGroupsQuery,
  GetMeCompanyGroupsQueryVariables,
  GetObjectiveCategories,
  GetObjectiveCategoriesQuery,
  GetObjectiveCategoriesQueryVariables,
  ObjectiveCategory,
  Group,
  GetPeriodTeamsQuery,
  GetPeriodTeamsQueryVariables,
  GetPeriodTeams,
  CompanyFiscalYear,
  Maybe,
} from '../generated/graphql';
import { takeAwayMaybeElement, getArrayValuesByKey, hasElement } from '../utils/array';
import { getActiveTeams, getParentTeamIdsFromPath, periodTeamsToTeams } from '../utils/team';
import { useUserRole } from './use-me';
import { isBetween } from '../utils/date-fns';
import addDays from 'date-fns/addDays';
import addYears from 'date-fns/addYears';
import { comparatorOrder } from '../utils/comparators';

export const useCompany = () => {
  const { me } = useContext(AuthContext);
  return me?.company!;
};

export const useCompanyId = () => {
  const { me } = useContext(AuthContext);
  return me?.company?.id!;
};
export const useCompanyTeams = (options: Record<any, any> = {}) => {
  const withManagers = options?.withManagers || false;
  const skip = options?.skip || false;

  const { loading, data } = useQuery<GetMeCompanyTeamsQuery, GetMeCompanyTeamsQueryVariables>(
    GetMeCompanyTeams,
    {
      skip,
      fetchPolicy: 'cache-first',
      variables: {
        withManagers,
      },
    },
  );

  const companyTeams = takeAwayMaybeElement<Team[]>(data?.teams);

  return {
    loading: loading,
    teams: companyTeams,
  };
};

interface UseCompanyPeriodTeamsOptions {
  periodId?: Maybe<string> | undefined;
  withManagers?: boolean;
  skip?: boolean;
}
export const useCompanyPeriodTeams = (options: UseCompanyPeriodTeamsOptions) => {
  const periodId = options?.periodId ?? null;
  const withManagers = options?.withManagers || false;
  const skip = options?.skip || !periodId || false;

  const { data, loading } = useQuery<GetPeriodTeamsQuery, GetPeriodTeamsQueryVariables>(
    GetPeriodTeams,
    {
      skip,
      fetchPolicy: 'cache-first',
      variables: {
        getParent: false,
        getManagers: withManagers,
        getMembers: false,
        getSubTeamMembers: false,
        getSubTeamManagers: withManagers,
        filters: {
          periodId: periodId!,
        },
      },
    },
  );

  const teams = periodTeamsToTeams(data?.teams);

  return {
    loading,
    teams,
  };
};

export const useCompanyActiveTeams = (options: Record<any, any> = {}) => {
  const { loading, teams } = useCompanyTeams(options);
  return {
    teams: getActiveTeams(teams),
    loading,
  };
};

export const useGetTeam = (options: Record<any, any> = {}) => {
  const { periodId } = options;
  const resCompanyTeams = useCompanyTeams({ ...options, skip: !!periodId });
  const resCompanyPeriodTeams = useCompanyPeriodTeams({ ...options, skip: !periodId });
  const { loading, teams } = (() => {
    if (periodId) {
      return resCompanyPeriodTeams;
    }
    return resCompanyTeams;
  })();

  return (teamId: string) => {
    return {
      team: teams.find(t => t.id === teamId),
      loading,
    };
  };
};

export const useGetTeams = (options: Record<any, any> = {}) => {
  const { loading, teams } = useCompanyTeams(options);

  return (teamIds: string[]) => {
    return {
      teams: teams.filter(t => teamIds?.includes(t.id)),
      loading,
    };
  };
};

export const useCompanyHrTeams = (options: Record<any, any> = {}) => {
  const { me, hasAnyGlobalHrRole } = useUserRole();
  const { scopes, teamScopedOnly = false } = options;

  const { loading, teams } = useCompanyTeams(options);

  if (!hasElement(teams)) {
    return {
      loading: false,
      teams: [],
    };
  }

  const hrScopedRoles = [
    UserScopedRoleKeys.HrManagement,
    UserScopedRoleKeys.HrDataExport,
    UserScopedRoleKeys.HrSetting,
  ].filter(scope => {
    if (!scopes) {
      return true;
    }
    return scopes.includes(scope);
  });

  const scopedRoles = takeAwayMaybeElement<ScopedRole[]>(me.scopedRoles).filter(scopedRole =>
    hrScopedRoles.includes(scopedRole.role.key),
  );

  if (!teamScopedOnly && hasAnyGlobalHrRole) {
    return {
      loading,
      teams,
    };
  }

  const scopedTeamIds = scopedRoles.reduce((acc: string[], scopedRole: ScopedRole) => {
    const teamScopes = scopedRole.scopes?.filter(s => s?.sourceType === ScopeSourceTypes.Team);
    return [...acc, ...getArrayValuesByKey(teamScopes, 'sourceId')];
  }, []);

  const resultTeams = teams.filter((team: Team) => {
    const parentIds = team ? getParentTeamIdsFromPath(team) : [];

    return scopedTeamIds.some(sti => {
      if (sti === team.id) {
        return true;
      }
      if (parentIds.includes(sti)) {
        return true;
      }

      return false;
    });
  });

  return {
    loading,
    teams: resultTeams,
  };
};

export const useCompanyGroups = () => {
  const { loading, data } = useQuery<GetMeCompanyGroupsQuery, GetMeCompanyGroupsQueryVariables>(
    GetMeCompanyGroups,
    {
      fetchPolicy: 'cache-first',
    },
  );

  const groups = takeAwayMaybeElement<Group[]>(data?.groups);
  return {
    loading,
    groups,
  };
};

export const useCompanyBilling = () => {
  const { data, loading } = useQuery<GetMeCompanyBillingQuery, GetMeCompanyBillingQueryVariables>(
    GetMeCompanyBilling,
    {
      fetchPolicy: 'cache-first',
    },
  );

  const company = data?.company;
  return {
    loading,
    id: company?.id,
    status: company?.status ?? null,
    expiredAt: company?.expiredAt ?? null,
    memberCounts: company?.memberCounts ?? null,
    pricings: company?.pricings ?? null,
    fiscalYears: company?.fiscalYears ?? null,
  };
};

export const useCompanySettings = () => {
  const { data, refetch, loading } = useQuery<
    GetMeCompanySettingsQuery,
    GetMeCompanySettingsQueryVariables
  >(GetMeCompanySettings, {
    fetchPolicy: 'cache-first',
  });

  const company = data?.company;
  return {
    loading,
    refetch,
    id: company?.id!,
    notificationSetting: company?.notificationSetting ?? null,
    teamSetting: company?.teamSetting ?? null,
    objectiveSetting: company?.objectiveSetting ?? null,
    o3Setting: company?.o3Setting ?? null,
    checkInSetting: company?.checkInSetting ?? null,
  };
};

export const useCompanyPeriod = () => {
  const { me } = useContext(AuthContext);
  const company = me?.company;

  return {
    loading: false,
    id: company?.id,
    periods: {
      month: takeAwayMaybeElement<Period[]>(company?.periods?.month),
      fourMonth: takeAwayMaybeElement<Period[]>(company?.periods?.fourMonth),
      quarter: takeAwayMaybeElement<Period[]>(company?.periods?.quarter),
      halfYear: takeAwayMaybeElement<Period[]>(company?.periods?.halfYear),
      year: takeAwayMaybeElement<Period[]>(company?.periods?.year),
    },
    currentPeriod: company?.currentPeriod ?? null,
  };
};

export const useGetCurrentFiscalYear = () => {
  const companyBilling = useCompanyBilling();
  return (): Maybe<CompanyFiscalYear> | undefined =>
    companyBilling?.fiscalYears?.find(fiscalYear => {
      return isBetween(new Date(), fiscalYear?.startAt, fiscalYear?.endAt);
    });
};

export const useGetNextFiscalYear = () => {
  const companyBilling = useCompanyBilling();
  const getCurrentFiscalYear = useGetCurrentFiscalYear();

  return () => {
    const fiscalYears = companyBilling?.fiscalYears || [];

    const currentFiscalYearIndex = fiscalYears.findIndex(fiscalYear =>
      isBetween(new Date(), fiscalYear?.startAt, fiscalYear?.endAt),
    );

    if (currentFiscalYearIndex < 0) {
      return null;
    }

    const currentFiscalYear = getCurrentFiscalYear();
    const dummyNextFiscalYear = {
      startAt: addDays(currentFiscalYear?.endAt || new Date(), 1),
      endAt: addYears(currentFiscalYear?.endAt || new Date(), 1),
    };

    return fiscalYears[currentFiscalYearIndex + 1] || dummyNextFiscalYear;
  };
};

export const useGetObjectiveCategories = (options: Record<any, any> = {}) => {
  const onCompleted = options.onCompleted;

  const { data, loading } = useQuery<
    GetObjectiveCategoriesQuery,
    GetObjectiveCategoriesQueryVariables
  >(GetObjectiveCategories, {
    fetchPolicy: 'cache-first',
    onCompleted,
  });

  const objectiveCategories = takeAwayMaybeElement<ObjectiveCategory[]>(data?.categories);
  objectiveCategories.sort(comparatorOrder);

  return {
    loading,
    objectiveCategories,
  };
};
