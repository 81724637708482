import styled, { css } from 'styled-components';
import { propGet } from 're-design';
import { WarningBarWrapper } from '../../atoms/WarningBar/styled';

export const LayoutHeader = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 50;
`;

export const LayoutBody = styled.div`
  display: flex;
  width: 100%;
  min-height: calc(100vh - 56px);
  margin-top: 56px;
  align-items: stretch;
  flex-wrap: wrap;
  overflow: hidden;
`;

interface LayoutBodySidebarProps {
  opened?: boolean;
}

export const LayoutBodySidebar = styled.div`
  width: 286px;
  background: var(--ui-01);
  top: 56px;
  position: fixed;
  left: 0;
  height: calc(100vh - 56px);
  overflow: hidden;
  z-index: 100;

  @media ${propGet('breakpoint.max.tablet')} {
    transform: translateX(-286px);
    opacity: 0.5;

    ${(props: LayoutBodySidebarProps) =>
      props.opened &&
      css`
        transform: translateX(0);
        opacity: 1;
      `}
  }
`;

export const AppMobileSidebarBackground = styled.div`
  background: var(--shadow-01);
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  z-index: 1;
`;

export const LayoutBodySidebarWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

export const LayoutBodyContent = styled.div`
  max-width: calc(100% - 286px);
  position: relative;
  left: 286px;
  min-height: calc(100vh - 56px);
  overflow-x: hidden;
  flex-grow: 1;
  background: var(--ui-background-01);
  border-top-left-radius: 6px;

  @media ${propGet('breakpoint.max.tablet')} {
    left: 0;
    max-width: 100%;
  }
`;
export const LayoutBodyContentChildren = styled.div``;

export const Layout = styled.div`
  position: relative;
  display: block;
  min-height: 100%;
  background: var(--ui-background-02);
  color: var(--text-01);

  ${WarningBarWrapper} {
    & + ${LayoutHeader} {
      top: 44px;
    }

    & ~ ${LayoutBody} {
      ${LayoutBodySidebar}, ${LayoutBody} {
        top: calc(56px + 44px);
        height: calc(100vh - 56px - 44px);
      }
    }
  }
`;
