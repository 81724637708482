import { Route, Redirect } from 'react-router-dom';
import { PlanTypes } from '../../../../generated/graphql';
import url from '../../../../constants/url';
import { usePlanType } from '../../../../lib/use-me';

function StandardPlanRoute(props: any) {
  const companyPlanType = usePlanType();
  return companyPlanType === PlanTypes.Standard || companyPlanType === PlanTypes.Full ? (
    <Route {...props} />
  ) : (
    <Redirect to={url.root} />
  );
}

export default StandardPlanRoute;
