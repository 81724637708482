import { memo, lazy, Suspense, useEffect } from 'react';
import { Switch, Route, Router, Redirect } from 'react-router-dom';
import { buildPathAndQuery } from '../../../utils/url';
import MemberOnly from './MemberOnly/MemberOnly';
import url from '../../../constants/url';
import SideSheetManager from '../../molecules/SideSheetManager';
import { useRouter } from '../../../lib/use-router';
import LocationHandler from '../../organisms/LocationHandler';
import ModalManager from '../../molecules/ModalManager';
import { SheetTemplate } from '../../molecules/SheetTemplate';
import ComponentLoader from '../../molecules/ComponentLoader';
import DefaultLayout from '../../layouts/DefaultLayout';
import LoadingPage from './Loading';
import { isAvailableLanguage, useSetLanguage } from '../../../lib/use-language';

import HomeRedirect from './Plans/HomeRedirect';
import StandardPlanRoute from './Plans/Standard';
import Ppo3PlanRoute from './Plans/Ppo3';

import PageError404 from '../Error/404';
import PageError503 from '../Error/503';

const Ppo3Router = lazy(() => import('../PlanPpo3/Router'));
const SettingsRouter = lazy(() => import('../Settings/Router'));
const AccountRouter = lazy(() => import('../Account/Router'));

const SelfManagementRouter = lazy(() => import('../PlanStandard/SelfManagement/Router'));
const PeopleManagementRouter = lazy(() => import('../PlanStandard/PeopleManagement/Router'));
const OrganizationRouter = lazy(() => import('../PlanStandard/Organization/Router'));
const HrRouter = lazy(() => import('../PlanStandard/Hr/Router'));

const O3sSheetPage = lazy(() => import('../O3s/Sheet'));
const ExtO3sSheetPage = lazy(() => import('../O3s/ExtSheet'));
const FeedbackSheetPage = lazy(() => import('../Feedback'));
const ObjectiveSheetPage = lazy(() => import('../ObjectiveSheet'));

const RegisterRoute = lazy(() => import('../Register'));
const PasswordResetRegisterPage = lazy(() => import('../PasswordReset/Register'));
const PasswordResetRequestPage = lazy(() => import('../PasswordReset/Request'));
const LoginPage = lazy(() => import('../Login'));
const SignUpRoute = lazy(() => import('../Signup'));
const OnBoardingRoute = lazy(() => import('../OnBoarding/Router'));

const ImportTeams = lazy(() => import('../Import/Teams'));
const ImportMembers = lazy(() => import('../Import/Members'));
const ImportPairs = lazy(() => import('../Import/Pairs'));
const ImportReportLine = lazy(() => import('../Import/ReportLine'));

const Test = lazy(() => import('../Test'));

const AppRoutes = memo(() => {
  return (
    <Suspense fallback={<LoadingPage />}>
      <Switch>
        <Redirect exact from={url.root} to={url.home} />
        <Route path={url.login.index} component={LoginPage} />
        <Route path={url.signup.index} component={SignUpRoute} />
        <Route exact path={url.passwordReset.requestForm} component={PasswordResetRequestPage} />
        <Route exact path={url.passwordReset.registerForm} component={PasswordResetRegisterPage} />
        <MemberOnly>
          <Switch>
            <Route path={url.register.index} component={RegisterRoute} />
            <Route path={url.onboarding.index} component={OnBoardingRoute} />
            <DefaultLayout>
              <LocationHandler />
              <ModalManager />
              <SideSheetManager />

              <Switch>
                {/* 共通 */}

                {process.env.NODE_ENV !== 'production' && (
                  <Route path={url.test.index} component={Test} />
                )}

                <Route
                  path={url.o3s.meetings.detail.index}
                  render={props => {
                    return (
                      <Suspense
                        fallback={
                          <SheetTemplate topBarInfo={null}>
                            <ComponentLoader />
                          </SheetTemplate>
                        }
                      >
                        <O3sSheetPage {...props} />
                      </Suspense>
                    );
                  }}
                />

                <Route
                  path={url.extO3s.meetings.detail.index}
                  render={props => {
                    return (
                      <Suspense
                        fallback={
                          <SheetTemplate topBarInfo={null}>
                            <ComponentLoader />
                          </SheetTemplate>
                        }
                      >
                        <ExtO3sSheetPage {...props} />
                      </Suspense>
                    );
                  }}
                />

                <Route
                  path={url.feedbacks.detail.sheets.detail.index}
                  render={(props: any) => {
                    return (
                      <Suspense
                        fallback={
                          <SheetTemplate topBarInfo={null}>
                            <ComponentLoader />
                          </SheetTemplate>
                        }
                      >
                        <FeedbackSheetPage {...props} />
                      </Suspense>
                    );
                  }}
                />

                <Route
                  path={url.objectiveSheets.detail.index}
                  render={props => {
                    return (
                      <Suspense
                        fallback={
                          <SheetTemplate topBarInfo={null}>
                            <ComponentLoader />
                          </SheetTemplate>
                        }
                      >
                        <ObjectiveSheetPage {...props} />
                      </Suspense>
                    );
                  }}
                />

                <Route
                  path={url.settings.index}
                  render={(props: any) => (
                    <Suspense fallback="">
                      <SettingsRouter {...props} />
                    </Suspense>
                  )}
                />

                <Route
                  path={url.account.index}
                  render={(props: any) => (
                    <Suspense fallback="">
                      <AccountRouter {...props} />
                    </Suspense>
                  )}
                />

                {/* プランごと */}
                <StandardPlanRoute
                  path={url.selfManagement.index}
                  render={(props: any) => (
                    <Suspense fallback="">
                      <SelfManagementRouter {...props} />
                    </Suspense>
                  )}
                />

                <StandardPlanRoute
                  path={url.peopleManagement.index}
                  render={(props: any) => (
                    <Suspense fallback="">
                      <PeopleManagementRouter {...props} />
                    </Suspense>
                  )}
                />

                <StandardPlanRoute
                  path={url.organization.index}
                  render={(props: any) => (
                    <Suspense fallback="">
                      <OrganizationRouter {...props} />
                    </Suspense>
                  )}
                />

                <StandardPlanRoute
                  path={url.hr.index}
                  render={(props: any) => (
                    <Suspense fallback="">
                      <HrRouter {...props} />
                    </Suspense>
                  )}
                />

                <Ppo3PlanRoute
                  path={url.ppo3.index}
                  render={(props: any) => (
                    <Suspense fallback="">
                      <Ppo3Router {...props} />
                    </Suspense>
                  )}
                />

                <Route exact path={url.import.teams} component={ImportTeams} />
                <Route exact path={url.import.members} component={ImportMembers} />
                <Route exact path={url.import.pairs} component={ImportPairs} />
                <Route exact path={url.import.reportLine} component={ImportReportLine} />

                {/*OLD ROUTES*/}
                <Redirect from="/checkin/objective" to={url.selfManagement.checkIn.index} />
                <Redirect from="/company/members" to={url.organization.members.index} />
                <Redirect from="/company/teams/:teamId" to={url.organization.teams.detail.index} />
                <Redirect from="/company/teams" to={url.organization.company.index} />
                <Redirect from="/company" to={url.organization.company.index} />
                <Redirect
                  from="/home/feedbacks-in-charge/:feedbackId/not-submitted"
                  to={url.peopleManagement.feedbacks.detail.notSubmitted}
                />
                <Redirect
                  from="/home/feedbacks-in-charge/:feedbackId/viewable"
                  to={url.peopleManagement.feedbacks.detail.viewable}
                />
                <Redirect
                  from="/home/feedbacks-in-charge/:feedbackId/sheets/:sheetId"
                  to={url.feedbacks.detail.sheets.detail.index}
                />
                <Redirect
                  from="/home/feedbacks-in-charge/:feedbackId"
                  to={url.peopleManagement.feedbacks.detail.index}
                />
                <Redirect from="/home/feedbacks-in-charge" to={url.peopleManagement.index} />
                <Redirect from="/home/member-board" to={url.peopleManagement.memberBoard.index} />
                <Redirect from="/home/members" to={url.organization.members.index} />
                <Redirect
                  from="/home/my-company/teams/:teamId"
                  to={url.organization.teams.detail.index}
                />
                <Redirect from="/home/my-company" to={url.organization.company.index} />
                <Redirect
                  from="/home/my-feedbacks/:feedbackId/sheets/:sheetId"
                  to={url.feedbacks.detail.sheets.detail.index}
                />
                <Redirect from="/home/my-team/members" to={url.organization.members.index} />
                <Redirect from="/home/my-team" to={url.organization.index} />
                <Redirect from="/home/objectives" to={url.selfManagement.objectives.index} />
                <Redirect
                  from="/home/one-on-ones/meetings/:meetingId/pre-survey"
                  to={url.o3s.meetings.detail.preSurvey.index}
                />
                <Redirect
                  from="/home/one-on-ones/meetings/:meetingId"
                  to={url.o3s.meetings.detail.index}
                />
                <Redirect from="/home/one-on-ones/current" to={url.selfManagement.o3s.current} />
                <Redirect from="/home/one-on-ones/past" to={url.selfManagement.o3s.past} />
                <Redirect from="/home/one-on-ones" to={url.selfManagement.o3s.index} />
                <Redirect from="/integrations" to={url.settings.index} />
                <Redirect from="/introduction" to={url.root} />
                <Redirect from="/objectives" to={url.selfManagement.objectives.index} />
                <Redirect from="/one-on-ones" to={url.selfManagement.o3s.index} />
                <Redirect from="/team/members" to={url.organization.members.index} />
                <Redirect from="/team" to={url.organization.company.index} />
                <Redirect from="/teams/:teamId" to={url.organization.teams.detail.index} />

                {/*HOME REDIRECT*/}
                <HomeRedirect />
                <Route component={PageError404} />
              </Switch>
            </DefaultLayout>
          </Switch>
        </MemberOnly>
        <Route component={PageError404} />
      </Switch>
    </Suspense>
  );
});

const AppRouter = memo(() => {
  const { history, query, replace, location } = useRouter();

  const setLang = useSetLanguage();
  useEffect(() => {
    if (query.lang) {
      if (isAvailableLanguage(query.lang)) {
        setLang(query.lang);
      }
      replace(buildPathAndQuery({ location, removing: ['lang'] }));
    }
  }, [setLang, query, location, replace]);

  const isMaintenance = process.env.REACT_APP_MAINTENANCE_MODE === 'true';
  if (isMaintenance) {
    return (
      <Suspense fallback={<LoadingPage />}>
        <PageError503 />
      </Suspense>
    );
  }

  return (
    <Router history={history}>
      <AppRoutes />
    </Router>
  );
});

export default AppRouter;
