import { FC, lazy, Suspense, memo, ReactNode } from 'react';
import { useUserRole } from '../../../lib/use-me';

import {
  Layout,
  LayoutHeader,
  LayoutBody,
  LayoutBodyContent,
  LayoutBodyContentChildren,
  LayoutBodySidebar,
  LayoutBodySidebarWrap,
  AppMobileSidebarBackground,
} from './styled';

import InvalidBrowser from '../../organisms/InvalidBrowser';
import { useLocaleStorage } from '../../../lib/local-storage';
import InvalidCompanyStatusBar from '../../organisms/InvalidCompanyStatusBar';
import Responsive from '../../atoms/Responsive';

const LayoutBodyContentHrPeopleManagement = lazy(
  () => import('../../organisms/_layouts/LayoutBodyContentHrPeopleManagement'),
);
const AppHeader = lazy(() => import('../../organisms/_layouts/AppHeader'));
const AppSidebar = lazy(() => import('../../organisms/_layouts/AppSidebar'));
const AppMobileSidebar = lazy(() =>
  import('../../organisms/_layouts/AppSidebar').then(module => ({
    default: module.AppMobileSidebar,
  })),
);

interface DefaultLayoutProps {
  children?: ReactNode;
}

const DefaultLayoutSidebar = () => {
  const { me, permissions } = useUserRole();
  const [sidebarOpen, setSidebarOpen] = useLocaleStorage('drawer', false, null);

  return (
    <>
      {sidebarOpen && (
        <AppMobileSidebarBackground
          style={{
            zIndex: sidebarOpen ? 10999 : 100,
            top: '56px',
          }}
          onClick={() => setSidebarOpen(false)}
        />
      )}
      <LayoutBodySidebar
        opened={sidebarOpen}
        style={{
          zIndex: sidebarOpen ? 11000 : 100,
        }}
      >
        <LayoutBodySidebarWrap>
          <Suspense fallback="">
            {sidebarOpen && (
              <Responsive mobile tablet useCss={false}>
                <AppMobileSidebar permissions={permissions} me={me} />
              </Responsive>
            )}
            <Responsive laptop useCss={false}>
              <AppSidebar permissions={permissions} me={me} />
            </Responsive>
          </Suspense>
        </LayoutBodySidebarWrap>
      </LayoutBodySidebar>
    </>
  );
};

const DefaultLayout: FC<DefaultLayoutProps> = memo(({ children }) => {
  return (
    <Layout>
      <InvalidCompanyStatusBar />
      <LayoutHeader>
        <Suspense fallback="">
          <AppHeader />
        </Suspense>
      </LayoutHeader>
      <LayoutBody>
        <DefaultLayoutSidebar />
        <LayoutBodyContent>
          <InvalidBrowser />
          <Suspense fallback="">
            <LayoutBodyContentHrPeopleManagement />
          </Suspense>
          <LayoutBodyContentChildren>{children}</LayoutBodyContentChildren>
        </LayoutBodyContent>
      </LayoutBody>
    </Layout>
  );
});

export default DefaultLayout;
