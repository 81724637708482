import { takeAwayEmptyElement } from './array';

export const omit = <R = object, K extends string = string>(
  obj: Record<any, any>,
  keys: K | K[],
): Omit<R, K> => {
  const keysToRemove = takeAwayEmptyElement<string[]>(keys);
  return Object.fromEntries(Object.entries(obj).filter(([k]) => !keysToRemove.includes(k))) as Omit<
    R,
    K
  >;
};

export const pick = (object: Record<any, any>, keys: string | string[]) => {
  const keysToPick = takeAwayEmptyElement<string[]>(keys);

  return keysToPick.reduce((obj: Record<string, any>, key: string) => {
    if (object && key in object) {
      obj[key] = object[key];
    }
    return obj;
  }, {});
};

export const uniqBy = (arr: any[], predicate: Function | string | null) => {
  if (predicate === '' || predicate === null) return arr;

  const cb = typeof predicate === 'function' ? predicate : (o: any) => o[predicate];

  return [
    ...arr
      .reduce((map, item) => {
        const key = item === null || item === undefined ? item : cb(item);

        map.has(key) || map.set(key, item);

        return map;
      }, new Map())
      .values(),
  ];
};

export const uniq = <T>(array: T[]) => [...new Set(array)];

export const groupBy = (
  collection: any,
  iteratee: ((key: any) => any) | string = (x: any) => x,
) => {
  const it = typeof iteratee === 'function' ? iteratee : (item: any) => item[iteratee as string];

  const array = collection
    ? Array.isArray(collection)
      ? collection
      : Object.values(collection)
    : [];

  return array.reduce((r, e) => {
    const k = it(e);

    r[k] = r[k] || [];

    r[k].push(e);

    return r;
  }, {});
};

export const chunk = (input: any[], size: number) => {
  if (size <= 0) return [];
  return input.reduce((arr, item, idx) => {
    return idx % size === 0
      ? [...arr, [item]]
      : [...arr.slice(0, -1), [...((arr.slice(-1) || [])[0] || []), item]];
  }, []);
};

export const get = (obj: any, path: string | number, defaultValue: any = undefined) => {
  const travel = (regexp: any) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
};

export const isNumber = (value: any) => typeof value === 'number' && isFinite(value);

export const isString = (a: any) => typeof a === 'string';

export const times = (i: number, fn?: (i: number) => any) =>
  fn ? [...Array(i).keys()].map(fn) : [...Array(i).keys()];

export const debounce = (func: Function, delay: number, { leading }: any = {}) => {
  let timerId: any;

  return (...args: any) => {
    if (!timerId && leading) {
      func(...args);
    }
    clearTimeout(timerId);

    timerId = setTimeout(() => func(...args), delay);
  };
};

export const range = (start: number, end: number, increment: number) => {
  // if the end is not defined...
  const isEndDef = typeof end !== 'undefined';
  // ...the first argument should be the end of the range...
  end = isEndDef ? end : start;
  // ...and 0 should be the start
  start = isEndDef ? start : 0;

  // if the increment is not defined, we could need a +1 or -1
  // depending on whether we are going up or down
  if (typeof increment === 'undefined') {
    increment = Math.sign(end - start);
  }

  // calculating the lenght of the array, which has always to be positive
  const length = Math.abs((end - start) / (increment || 1));

  // In order to return the right result, we need to create a new array
  // with the calculated length and fill it with the items starting from
  // the start value + the value of increment.
  const { result } = Array.from({ length }).reduce(
    ({ result, current }) => ({
      // append the current value to the result array
      result: [...result, current],
      // adding the increment to the current item
      // to be used in the next iteration
      current: current + increment,
    }),
    { current: start, result: [] },
  );

  return result;
};

export const has = (obj: Record<string, any>, key: string): boolean => key in obj;

export const isEqual = (val1: any, val2: any) => {
  try {
    const jsonVal1 = JSON.stringify(val1, Object.keys(val1).sort());
    const jsonVal2 = JSON.stringify(val2, Object.keys(val2).sort());

    return jsonVal1 === jsonVal2;
  } catch {
    return false;
  }
};

export const uniqueId = (
  counter =>
  (str = '') =>
    `${str}${++counter}`
)(0);

export const mapKeys = (obj: any, mapper: any) =>
  Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [mapper(value, key)]: value,
    }),
    {},
  );
