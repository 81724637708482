import { memo, FC, useCallback, useMemo, ReactNode, createContext, useReducer } from 'react';
import { isEqual } from '../../utils/lodash';
import { takeAwayEmptyElement } from '../../utils/array';

export type SideSheetActionType =
  | 'OPEN_SIDE_SHEET'
  | 'CLOSE_SIDE_SHEET'
  | 'REGISTER_SIDE_SHEET'
  | 'SWITCH_SIDE_SHEET'
  | 'BACK_SIDE_SHEET';

type SideSheetAction = {
  type: SideSheetActionType;
  payload?: any;
};

export type SideSheetState = {
  sideSheetType: FC<any>;
  sideSheetProps: any;
  lastAction?: SideSheetAction['type'] | null;
};

export type SideSheetOptions = Record<string, unknown>;

interface SideSheetActionContextProps {
  openSideSheet<T>(
    sideSheetType: FC<T>,
    sideSheetProps: T,
    sideSheetOptions?: SideSheetOptions,
  ): void;
  closeSideSheet: () => void;
  switchSideSheet: (
    sideSheetType: FC<any>,
    sideSheetProps: any,
    sideSheetOptions?: SideSheetOptions,
  ) => void;
  isOpened: boolean;
  lastAction?: SideSheetAction['type'] | null;
}

export const SideSheetStateContext = createContext<SideSheetState[]>([]);
export const SideSheetStateConsumer = SideSheetStateContext.Consumer;
export const SideSheetActionContext = createContext<SideSheetActionContextProps>(
  {} as SideSheetActionContextProps,
);
export const SideSheetActionConsumer = SideSheetActionContext.Consumer;

const SideSheetReducer = (_state: SideSheetState[], action: SideSheetAction) => {
  const state = takeAwayEmptyElement<SideSheetState[]>(_state);

  switch (action.type) {
    case 'OPEN_SIDE_SHEET': {
      const { sideSheetType, sideSheetProps } = action.payload;
      const current: SideSheetState = state[state.length - 1];
      if (current && isEqual(current, { sideSheetType, sideSheetProps })) {
        return [];
      }
      return [{ lastAction: action.type, sideSheetType, sideSheetProps }];
    }
    case 'CLOSE_SIDE_SHEET': {
      return [];
    }
    case 'SWITCH_SIDE_SHEET': {
      const { sideSheetType, sideSheetProps } = action.payload;
      const current: SideSheetState = state[state.length - 1];
      if (current && isEqual(current, { sideSheetType, sideSheetProps })) {
        return state.length >= 2 ? state.slice(0, -1) : [];
      }
      return [...state, { lastAction: action.type, sideSheetType, sideSheetProps }];
    }

    case 'BACK_SIDE_SHEET': {
      const newState = state.slice(0, -1);
      if (newState.length) {
        Object.assign(newState[newState.length - 1], {
          lastAction: action.type,
        });
      }

      return [...newState];
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

interface SideSheetProviderProps {
  children?: ReactNode;
}
const SideSheetProvider: FC<SideSheetProviderProps> = memo(({ children }) => {
  const [state, dispatch] = useReducer(SideSheetReducer, []);
  const openSideSheet = useCallback((sideSheetType: FC<any>, sideSheetProps: any) => {
    dispatch({
      type: 'OPEN_SIDE_SHEET',
      payload: {
        sideSheetType,
        sideSheetProps,
      },
    });
  }, []);

  const closeSideSheet = useCallback(() => {
    dispatch({
      type: 'CLOSE_SIDE_SHEET',
    });
  }, []);

  const switchSideSheet = useCallback((sideSheetType: FC<any>, sideSheetProps: any) => {
    dispatch({
      type: 'SWITCH_SIDE_SHEET',
      payload: {
        sideSheetType,
        sideSheetProps,
      },
    });
  }, []);

  const isOpened = state.length > 1;
  const lastAction = state.length ? state[state.length - 1]?.lastAction : null;
  const actions = useMemo(
    () => ({
      openSideSheet,
      closeSideSheet,
      switchSideSheet,
      isOpened,
      lastAction,
    }),
    [isOpened, lastAction, openSideSheet, closeSideSheet, switchSideSheet],
  );

  return (
    <SideSheetStateContext.Provider value={state}>
      <SideSheetActionContext.Provider value={actions as SideSheetActionContextProps}>
        {children}
      </SideSheetActionContext.Provider>
    </SideSheetStateContext.Provider>
  );
});

export default SideSheetProvider;
