import { createContext, FC, ReactNode, useCallback, useState } from 'react';

import { setItem } from '../../../lib/local-storage';
import i18n from '../../../lib/i18n';

interface LangActionContext {
  setLang(str: string, userId?: string | undefined): void;
  getCurrentLang(): string;
}

export const LangActionContext = createContext<LangActionContext>({} as LangActionContext);
export const LangActionConsumer = LangActionContext.Consumer;

interface LangProviderProps {
  children?: ReactNode;
}
const LangProvider: FC<LangProviderProps> = ({ children }) => {
  const [currentLang, setCurrentLang] = useState<string>(i18n.language);

  const setLang = useCallback(
    (str: string, userId?: string | undefined) => {
      if (userId) {
        setItem(`currentLang-${userId}`, str);
      }
      setItem('currentLang', str);

      setCurrentLang(str);
      i18n.changeLanguage(str);
    },
    [setCurrentLang],
  );

  const getCurrentLang = useCallback(() => {
    return currentLang;
  }, [currentLang]);

  return (
    <LangActionContext.Provider
      value={{
        setLang,
        getCurrentLang,
      }}
    >
      {children}
    </LangActionContext.Provider>
  );
};

export default LangProvider;
