import { ReactNode, FC } from 'react';
import { Button } from 're-design';

interface SheetActionButtonProps {
  active?: boolean;
  onClick?: () => void;
  icon?: ReactNode;
  disabled?: boolean;
  readOnly?: boolean;
}

const SheetActionButton: FC<SheetActionButtonProps> = ({
  active,
  onClick,
  icon,
  disabled,
  readOnly,
}) => {
  return (
    <Button
      disabled={disabled}
      readOnly={readOnly}
      icon={icon}
      variant="white"
      onlyIcon
      active={active}
      onClick={onClick}
    />
  );
};

export default SheetActionButton;
