import { FC, createContext, useState, useEffect, ReactNode } from 'react';
import { HostInfo } from '../../generated/graphql';

const url = `${process.env.REACT_APP_API_SCHEME}://${process.env.REACT_APP_API_HOST}/hostinfo`;
interface HostContext {
  hostInfo: HostInfo | null | undefined;
}
export const HostContext = createContext<HostContext>({} as HostContext);

interface HostProviderProps {
  children?: ReactNode;
}
export const HostProvider: FC<HostProviderProps> = ({ children }) => {
  const [hostInfo, setHostInfo] = useState<HostInfo | null | undefined>(null);
  useEffect(() => {
    fetch(url, { method: 'GET' })
      .then(res => res.json())
      .then(data => setHostInfo(data));
  }, []);

  const value = { hostInfo };
  return <HostContext.Provider value={value}>{children}</HostContext.Provider>;
};
