import { useEffect, useRef } from 'react';
import { useRouter } from '../../../lib/use-router';
import { SIDESHEET_UUID, useCloseSideSheet, SideSheetKey } from '../../../lib/sidesheet';
import { useCloseModal } from '../../../lib/modal';
import url from '../../../constants/url';
import { buildUrlFromConstant } from '../../../utils/url';

const LocationHandler = () => {
  const { history, push, query, appLocationListener, location: routerLocation } = useRouter();
  const closeSideSheet = useCloseSideSheet();
  const closeSideSheetDisabledQuery = useCloseSideSheet({
    disableQuery: true,
  });

  const closeModal = useCloseModal();

  const previousLocationRef = useRef<null | string>(null);

  window.addEventListener('popstate', () => {
    if (!query.sd) {
      closeSideSheetDisabledQuery({
        nextLocationPathname: routerLocation.pathname,
      });
    }
  });

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      appLocationListener(location, action);

      if (previousLocationRef?.current && previousLocationRef?.current !== location.pathname) {
        closeModal();
        closeSideSheet({
          nextLocationPathname: location.pathname,
        });

        if ((location as any)?.state?.scroll !== false) {
          window.scrollTo(0, 0);
        }
      }
      previousLocationRef.current = location.pathname;
    });

    /* Self/People/Organization の画面にいないと、サイドシートを開きながら、myPageに移動されます。*/

    if (!previousLocationRef?.current && query.sd) {
      const modalDecryptedKey = JSON.parse(window.atob(query.sd!));

      switch (modalDecryptedKey?.c) {
        case SIDESHEET_UUID[SideSheetKey.Member]:
        case SIDESHEET_UUID[SideSheetKey.Team]:
          if (!`${routerLocation.pathname}`.includes(url.organization.index)) {
            push(
              buildUrlFromConstant(
                url.organization.members.index,
                {},
                {
                  sd: query.sd,
                },
              ),
            );
          }
          break;
        default:
          if (
            !`${routerLocation.pathname}`.includes(url.selfManagement.index) &&
            !`${routerLocation.pathname}`.includes(url.peopleManagement.index) &&
            !`${routerLocation.pathname}`.includes(url.organization.index)
          ) {
            push(
              buildUrlFromConstant(
                url.selfManagement.myPage.index,
                {},
                {
                  sd: query.sd,
                },
              ),
            );
          }
      }
    }

    return () => {
      unlisten();
    };
  }, [
    push,
    query,
    closeSideSheet,
    closeSideSheetDisabledQuery,
    routerLocation,
    closeModal,
    history,
    appLocationListener,
  ]);

  return null;
};

export default LocationHandler;
