import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AppLogo from '../../atoms/AppLogo';
import Loader from '../../atoms/Loader';
import { twJoin, twMerge } from 'tailwind-merge';

interface LoadingPageProps {
  relative?: boolean;
}

const LoadingPage: FC<LoadingPageProps> = ({ relative }) => {
  const { t } = useTranslation();
  return (
    <div className={twMerge(['fixed top-0 left-0 right-0 bottom-0', relative && 'w-full h-full'])}>
      <div className="flex items-center justify-center h-full">
        <div>
          <AppLogo width={150} height={34} />
          <Loader withMargin />
          <div
            className={twJoin([
              'mt-6 text-center text-sm text-base-02 dark:text-base-02-dark leading-[1.4]',
              '[&_br]:hidden xs:[&_br]:block',
            ])}
          >
            {t('読み込み中です。')}
            <br />
            {t('少々お待ち下さい…')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;
