import {
  ScopedRole,
  Scope,
  ScopeSourceTypes,
  UserRoles,
  User,
  UserScopedRoleKeys,
  Maybe,
  Team,
} from '../generated/graphql';
import { getArrayIds, takeAwayMaybeElement, takeAwayEmptyElement } from '../utils/array';
import { groupBy, uniq } from '../utils/lodash';

export const toScopedRole = (role: UserRoles, scopes: ScopedRole[], teamId: string | null) => {
  return scopes.reduce((acc, scope) => {
    const scopeScopes = takeAwayMaybeElement<Scope[]>(scope.scopes).filter(s => {
      if (!teamId) {
        return true;
      }
      return (
        (s.sourceType === ScopeSourceTypes.Team && s.sourceId === teamId) ||
        s.scopedTeamIds?.includes(teamId)
      );
    });
    scopeScopes.forEach(s => {
      acc.push({
        role,
        scopeSourceType: s.sourceType,
        scopeSourceId: s.sourceId,
        // scopedTeamIds: s.scopedTeamIds,
      });
    });
    return acc;
  }, [] as ScolePermissionRole[]) as ScolePermissionRole[];
};

export const hasCompanyScopedRole = (r: ScopedRole): boolean =>
  takeAwayMaybeElement<Scope[]>(r.scopes).some(
    scope => scope.sourceType === ScopeSourceTypes.Company,
  );

interface ScolePermissionRole {
  role: UserRoles;
  scopeSourceType: ScopeSourceTypes;
  scopeSourceId: string;
}
export function isPermittedScopedRole(r: ScopedRole, pr: ScolePermissionRole) {
  if ((r.role?.key as any) !== (pr.role as any)) return false; // role が一致しない

  // r と pr の role が一致している
  if (r.role?.key === UserScopedRoleKeys.Owner) return true; // global scope のみ
  if (r.role?.key === UserScopedRoleKeys.Member) return true; // global scope のみ

  // scope のチェック (global scope を持っているか)
  if (hasCompanyScopedRole(r)) return true; // global scope の権限を持っている

  // scope のチェック（team scope）
  if (
    r?.scopes?.some(
      s => s.sourceType === ScopeSourceTypes.Team && pr.scopeSourceType === ScopeSourceTypes.Team,
    )
  ) {
    if (r?.scopes?.some(s => s.sourceId === pr.scopeSourceId)) {
      return true;
    }
  }
  return false;
}

export const hasScopedRole = (user: User, permittedScopedRoles: ScolePermissionRole[]) => {
  const scopedRoles = takeAwayMaybeElement<ScopedRole[]>(user.scopedRoles);

  return scopedRoles.some(scopedRole =>
    permittedScopedRoles.some(psr => isPermittedScopedRole(scopedRole, psr)),
  );
};

export const toGlobalScopedRole = (user: User, role: UserRoles) => {
  return [
    {
      role,
      scopeSourceType: ScopeSourceTypes.Company,
      scopeSourceId: user.company?.id ?? '0',
    },
  ] as ScolePermissionRole[];
};

export const pickScopedRoles = (user: User, roles: UserRoles[]) => {
  return takeAwayMaybeElement<ScopedRole[]>(user.scopedRoles).filter(r =>
    roles.some(role => (r.role as any) === (role as any)),
  );
};

// export const getScopedTeamIds = (user: User, includeScopedTeamIds: boolean = false) => {
//   const hrScopedRoles = [UserScopedRoleKeys.HrManagement, UserScopedRoleKeys.HrDataExport, UserScopedRoleKeys.HrSetting];
//   const scopedRoles = takeAwayMaybeElement<ScopedRole[]>(user.scopedRoles).filter(scopedRole => hrScopedRoles.includes(scopedRole.role.key));
//
//   const scopeTeamIds = scopedRoles.reduce((acc, scopeRole) => {
//     const scopes = takeAwayMaybeElement<Scope[]>(scopeRole.scopes).filter(s => s.sourceType === ScopeSourceTypes.Team);
//     scopes.forEach(s => {
//       acc = [
//         ...acc,
//         s.sourceId,
//       ]
//       if(includeScopedTeamIds) {
//         acc = [
//           ...acc,
//           ...takeAwayEmptyElement(s.scopedTeamIds)
//         ]
//       }
//     })
//     return acc;
//   }, []);
//
//   return uniq(scopeTeamIds);
// }

export const getScopedByTeams = (
  user: User,
): Record<
  string,
  {
    teamId: string;
    role: UserScopedRoleKeys;
  }[]
> => {
  const hrScopedRoles = [
    UserScopedRoleKeys.HrManagement,
    UserScopedRoleKeys.HrDataExport,
    UserScopedRoleKeys.HrSetting,
  ];
  const scopedRoles = takeAwayMaybeElement<ScopedRole[]>(user.scopedRoles).filter(scopedRole =>
    hrScopedRoles.includes(scopedRole.role.key),
  );

  const scopeTeams = scopedRoles.reduce((acc, scopeRole) => {
    const scopes = takeAwayMaybeElement<Scope[]>(scopeRole.scopes).filter(
      s => s.sourceType === ScopeSourceTypes.Team,
    );
    scopes.forEach(s => {
      acc = [
        ...acc,
        {
          teamId: s.sourceId,
          role: scopeRole.role.key,
        },
      ];
    });
    return acc;
  }, [] as any);

  return groupBy(scopeTeams, 'teamId');
};

export const getScopedTeamIdsFromTeam = (user: User, teams: Team[], teamId: string): string[] => {
  const team = teams.find(t => t.id === teamId);
  if (!team) return [teamId];

  const hrScopedRoles = [
    UserScopedRoleKeys.HrManagement,
    UserScopedRoleKeys.HrDataExport,
    UserScopedRoleKeys.HrSetting,
  ];

  const scopedRoles = takeAwayMaybeElement<ScopedRole[]>(user.scopedRoles).filter(scopedRole =>
    hrScopedRoles.includes(scopedRole.role.key),
  );

  const hasCompanyTeamScope = scopedRoles.some(scopeRole => {
    return takeAwayMaybeElement<Scope[]>(scopeRole.scopes).some(
      s => s.sourceType === ScopeSourceTypes.Company,
    );
  });

  if (hasCompanyTeamScope) {
    const teamScopeTeamIds = getArrayIds(
      teams.filter(t => t.path?.split?.('/')?.find(id => id === teamId)),
    );

    return uniq(teamScopeTeamIds);
  }

  const scopeTeamIds = scopedRoles.reduce(
    (acc, scopeRole) => {
      const scopes = takeAwayMaybeElement<Scope[]>(scopeRole.scopes).filter(s => {
        return (
          s.sourceType === ScopeSourceTypes.Team &&
          (s.sourceId === teamId || s.scopedTeamIds?.includes(teamId))
        );
      });

      scopes.forEach(s => {
        const scopedTeamIds = takeAwayMaybeElement<string[]>(s.scopedTeamIds);
        const teamIndex = scopedTeamIds.findIndex(st => st === teamId);
        const childScopedTeamIds = scopedTeamIds.slice(teamIndex, scopedTeamIds.length);

        acc = [...acc, ...childScopedTeamIds];
      });
      return acc;
    },
    [teamId],
  );

  return uniq(scopeTeamIds);
};

export const isTeamIdInScopedTeam = (user: User, teamId: string) => {
  const hrScopedRoles = [
    UserScopedRoleKeys.HrManagement,
    UserScopedRoleKeys.HrDataExport,
    UserScopedRoleKeys.HrSetting,
  ];

  const scopedRoles = takeAwayMaybeElement<ScopedRole[]>(user.scopedRoles).filter(scopedRole =>
    hrScopedRoles.includes(scopedRole.role.key),
  );

  return scopedRoles.some(sr =>
    takeAwayMaybeElement<Scope[]>(sr.scopes).some(
      s =>
        s.sourceType === ScopeSourceTypes.Team &&
        (s.sourceId === teamId || takeAwayEmptyElement(s.scopedTeamIds).includes(teamId)),
    ),
  );
};

export interface ScopedsBySourceTypeInterface {
  key: string;
  teamId: string | null;
  roles: UserScopedRoleKeys[];
  sourceType: ScopeSourceTypes;
}

export const flatScopedsBySourceType = (user: Maybe<User>): ScopedsBySourceTypeInterface[] => {
  const scopedRoles = takeAwayMaybeElement<ScopedRole[]>(user?.scopedRoles);
  return scopedRoles.reduce((acc: any[], scopedRole) => {
    const scopes = takeAwayMaybeElement<Scope[]>(scopedRole?.scopes);
    scopes.forEach(scope => {
      const index = acc.findIndex(a => a?.key === `${scope.sourceType}-${scope.sourceId}`);
      if (index === -1) {
        acc.push({
          key: `${scope.sourceType}-${scope.sourceId}`,
          teamId: scope.sourceType === ScopeSourceTypes.Team ? scope.sourceId : null,
          roles: [scopedRole.role.key],
          sourceType: scope.sourceType,
        });
      } else {
        acc[index].roles.push(scopedRole.role.key);
      }
    });

    return acc;
  }, []);
};

export interface ScopedsByRoleInterface {
  role: UserScopedRoleKeys;
  sources: {
    type: ScopeSourceTypes;
    id: string;
  }[];
}

export const flatScopedsByRole = (user: Maybe<User>) => {
  const scopedRoles = takeAwayMaybeElement<ScopedRole[]>(user?.scopedRoles);
  return scopedRoles.reduce((acc: ScopedsByRoleInterface[], scopedRole) => {
    const scopes = takeAwayMaybeElement<Scope[]>(scopedRole?.scopes);
    scopes.forEach(scope => {
      const index = acc.findIndex(a => a?.role === `${scopedRole.role.key}`);
      if (index === -1) {
        acc.push({
          role: scopedRole.role.key,
          sources: [
            {
              type: scope.sourceType,
              id: scope.sourceId,
            },
          ],
        });
      } else {
        acc[index].sources.push({
          type: scope.sourceType,
          id: scope.sourceId,
        });
      }
    });

    return acc;
  }, []);
};
