import { FC, ReactNode } from 'react';
import { useRouter } from '../../../lib/use-router';
import { useTranslation } from 'react-i18next';
import url from '../../../constants/url';

import { icons } from 're-design';

import {
  Layout,
  LayoutTopbar,
  LayoutInner,
  LayoutBody,
  LayoutTopbarCloseButton,
  LayoutTopbarCloseButtonLabel,
  LayoutTopbarActions,
  LayoutTopbarInfo,
  LayoutTopbarContent,
} from './styled';

import PageError from '../PageError';

export const FullscreenModal: FC<{
  variant?: string;
  zIndex?: number;
  children?: ReactNode;
}> = ({ children, variant, zIndex = 5000 }) => {
  return (
    <Layout variant={variant} zIndex={zIndex}>
      {children}
    </Layout>
  );
};

interface FullscreenModalTopbarProps {
  onClose?(): void;
  closeLabel?: string | ReactNode;
  closeIcon?: ReactNode;
  info?: ReactNode;
  actions?: ReactNode;
}
export const FullscreenModalTopbar: FC<FullscreenModalTopbarProps> = ({
  closeLabel,
  onClose,
  info,
  actions,
  closeIcon,
}) => {
  const { t } = useTranslation();
  const { goBackOrReplace } = useRouter();

  const handleClose = () => {
    goBackOrReplace(url.index);
  };

  return (
    <LayoutTopbar>
      <LayoutTopbarContent>
        <LayoutTopbarCloseButton onClick={onClose || handleClose}>
          {closeIcon || <icons.CloseIcon />}
          {!!info || (
            <>
              {typeof closeLabel === 'string' ? (
                <LayoutTopbarCloseButtonLabel>
                  {closeLabel || t('閉じる')}
                </LayoutTopbarCloseButtonLabel>
              ) : (
                <>{closeLabel}</>
              )}
            </>
          )}
        </LayoutTopbarCloseButton>
        {info && <LayoutTopbarInfo>{info}</LayoutTopbarInfo>}
      </LayoutTopbarContent>
      {actions && <LayoutTopbarActions>{actions}</LayoutTopbarActions>}
    </LayoutTopbar>
  );
};

export const FullscreenModalInner: FC = ({ children }) => {
  return <LayoutInner>{children}</LayoutInner>;
};

interface FullscreenModalBodyProps {
  size?: 'large' | 'normal' | 'small';
  fluid?: boolean;
  children?: ReactNode;
}
export const FullscreenModalBody: FC<FullscreenModalBodyProps> = ({
  children,
  size,
  fluid = false,
}) => {
  return (
    <LayoutBody fluid={fluid} size={size}>
      {children}
    </LayoutBody>
  );
};

interface FullscreenModalErrorProps {
  refetch?(): void;
  onClose?(): void;
  topbarProps?: any;
  zIndex?: number;
}

export const FullscreenModalError: FC<FullscreenModalErrorProps> = ({
  refetch,
  onClose,
  topbarProps,
  zIndex,
}) => {
  return (
    <FullscreenModal zIndex={zIndex}>
      <FullscreenModalTopbar onClose={onClose} {...topbarProps} />
      <FullscreenModalInner>
        <PageError refetch={refetch} />
      </FullscreenModalInner>
    </FullscreenModal>
  );
};
