import { FC } from 'react';
import AppLogoWistant from './wistant';
import AppLogoMentors from './mentors';
import { useHostInfo } from '../../../lib/use-host';

export type AppLogoVariant = 'header' | 'loading' | 'header-fixed' | 'invoice' | 'auth' | 'error';
interface AppLogoProps {
  height?: number;
  width?: number;
  className?: string;
  variant?: AppLogoVariant;
}

const AppLogo: FC<AppLogoProps> = ({ ...props }) => {
  const hostInfo = useHostInfo();

  if (hostInfo?.extO3MentorOnly) {
    return <AppLogoMentors {...props} />;
  }
  return <AppLogoWistant {...props} />;
};

export default AppLogo;
