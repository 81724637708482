import { FC, ReactNode } from 'react';
import { toast } from 'react-toastify';
import { icons } from 're-design';

import { StyledToastContainer, ActionLabelButton } from './styled';

const CloseButton = <icons.CloseIcon width="18px" height="18px" fill="white" />;

interface ToastContainerProps {
  children?: ReactNode;
}

const ToastContainer: FC<ToastContainerProps> = ({ children }) => (
  <>
    <StyledToastContainer
      position="bottom-left"
      autoClose={5000}
      hideProgressBar
      closeOnClick
      draggable
      pauseOnHover
      closeButton={CloseButton}
    />
    {children}
  </>
);

export { toast, ActionLabelButton, CloseButton };
export type { Toast } from 'react-toastify';

export default ToastContainer;

/* NOTE:
# Usage
  - <ToastContainer /> を App root に配置
  - toast.success(content: ReactNode) を任意の場所でコール
# Spec
  - Wistant規定のToastは3種類で、「情報」「成功」「エラー」です。警告はありません。
*/
