import { memo, useState, useEffect, FC, ReactNode, createElement } from 'react';
import { size } from '../../../utils/breakpoint';
import { twMerge } from 'tailwind-merge';

export interface ResponsiveProps {
  min?: boolean;
  xs?: boolean;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
  mx?: boolean;
  mobile?: boolean;
  tablet?: boolean;
  laptop?: boolean;
  inline?: boolean;
  flex?: boolean;
  tag?: 'div' | 'span' | 'p';
  useCss?: boolean;
  children?: ReactNode;
  className?: string;
}

const Responsive: FC<ResponsiveProps> = memo(
  ({ children, tag = 'div', useCss = true, className, inline, flex, ...props }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
      if (useCss) return;

      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [useCss]);

    const isVisible = (() => {
      if (props.min) {
        if (windowWidth <= size.min - 1) {
          return true;
        }
      }

      if (props.xs) {
        if (windowWidth > size.min && windowWidth < size.xs) {
          return true;
        }
      }

      if (props.sm) {
        if (windowWidth > size.xs + 1 && windowWidth < size.sm) {
          return true;
        }
      }

      if (props.md) {
        if (windowWidth > size.sm + 1 && windowWidth < size.md) {
          return true;
        }
      }

      if (props.lg) {
        if (windowWidth > size.md + 1 && windowWidth < size.lg) {
          return true;
        }
      }

      if (props.xl) {
        if (windowWidth > size.lg + 1 && windowWidth < size.xl) {
          return true;
        }
      }

      if (props.mx) {
        if (windowWidth > size.xl + 1) {
          return true;
        }
      }

      if (props.mobile) {
        if (windowWidth < size.md) {
          return true;
        }
      }

      if (props.tablet) {
        if (windowWidth > size.md + 1 && windowWidth < size.lg) {
          return true;
        }
      }

      if (props.laptop) {
        if (windowWidth > size.lg + 1) {
          return true;
        }
      }

      return false;
    })();

    if (!useCss && !isVisible) {
      return null;
    }

    if (useCss) {
      const classNames = twMerge([
        'hidden',
        'xs:hidden',
        'sm:hidden',
        'md:hidden',
        'lg:hidden',
        'xl:hidden',
        '2xl:hidden',
        (props.min || props.mobile) &&
          (flex ? (inline ? 'inline-flex' : 'flex') : inline ? 'inline-block' : 'block'),
        (props.xs || props.mobile) &&
          (flex
            ? inline
              ? 'xs:inline-flex'
              : 'xs:flex'
            : inline
              ? 'xs:inline-block'
              : 'xs:block'),
        (props.sm || props.mobile) &&
          (flex
            ? inline
              ? 'sm:inline-flex'
              : 'sm:flex'
            : inline
              ? 'sm:inline-block'
              : 'sm:block'),
        (props.md || props.tablet) &&
          (flex
            ? inline
              ? 'md:inline-flex'
              : 'md:flex'
            : inline
              ? 'md:inline-block'
              : 'md:block'),
        (props.lg || props.laptop) &&
          (flex
            ? inline
              ? 'lg:inline-flex'
              : 'lg:flex'
            : inline
              ? 'lg:inline-block'
              : 'lg:block'),
        (props.xl || props.laptop) &&
          (flex
            ? inline
              ? 'xl:inline-flex'
              : 'xl:flex'
            : inline
              ? 'xl:inline-block'
              : 'xl:block'),
        (props.mx || props.laptop) &&
          (flex
            ? inline
              ? '2xl:inline-flex'
              : '2xl:flex'
            : inline
              ? '2xl:inline-block'
              : '2xl:block'),
        className,
      ]);

      const CustomTag = () =>
        createElement(
          `${tag}`,
          {
            ...props,
            className: classNames,
          },
          children,
        );

      /* @ts-expect-error */
      return <CustomTag>{children}</CustomTag>;
    }

    return <>{children}</>;
  },
);

export default Responsive;
