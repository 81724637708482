import { FC, useState, useEffect, ReactNode, SetStateAction, createContext, Dispatch } from 'react';
import { isMobile } from 'react-device-detect';
import hubspot from '../../utils/hubspot';

interface HubspotChatContextType {
  setVisibility: Dispatch<SetStateAction<number>>;
  visibility: number;
}

export const HubspotChatContext = createContext<HubspotChatContextType>(
  {} as HubspotChatContextType,
);

interface HubspotChatProviderProps {
  children?: ReactNode;
}

const HubspotChatProvider: FC<HubspotChatProviderProps> = ({ children }) => {
  const enabled = process.env.REACT_APP_HUBSPOT_ENABLED === 'true';
  const portalId = process.env.REACT_APP_HUBSPOT_APP_ID;
  const defaultVisibility = isMobile ? 0 : 1;

  const [hasLoaded, setHasLoaded] = useState(false);
  const [visibility, setVisibility] = useState<number>(defaultVisibility);

  // スクリプトをロードして window.HubSpotConversations を初期化する
  useEffect(() => {
    if (!enabled) return;

    // Add event listener.
    window.hsConversationsOnReady = [() => setHasLoaded(true)];
    window.hsConversationsSettings = {
      loadImmediately: false,
      // inlineEmbedSelector: '#hubspot-chat',
      enableWidgetCookieBanner: true,
      disableAttachment: true,
    };

    // Create script component.
    const script = document.createElement('script');
    script.src = `https://js.hs-scripts.com/${portalId}.js`;
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      window.hsConversationsOnReady = [];
    };
  }, [enabled, portalId]);

  // window.HubSpotConversations.widget の表示/非表示をコントロールする
  useEffect(() => {
    if (!enabled) return;
    if (isMobile) return;
    if (!hasLoaded) return;
    if (!hubspot.hasLoaded()) return;

    if (visibility > 0) {
      hubspot.show();
    } else {
      hubspot.hide();
    }
  }, [enabled, hasLoaded, visibility]);

  const value = {
    setVisibility,
    visibility,
  };

  return <HubspotChatContext.Provider value={value}>{children}</HubspotChatContext.Provider>;
};

export default HubspotChatProvider;
