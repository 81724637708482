import { useMemo, FC, useCallback, useState, useEffect, ReactNode, createContext } from 'react';
import { ThemeProvider as ReDesignThemeProvider, ThemeInterface } from 're-design';

import lightTheme from './theme/theme_light';
import darkTheme from './theme/theme_dark';
import { setItem, getItem } from '../../../lib/local-storage';

export type ThemeVariant = 'light' | 'dark';

interface ThemeActionContext {
  switchTheme: () => void;
  setLightTheme: () => void;
  setDarkTheme: () => void;
  currentTheme: ThemeVariant;
}

export const ThemeActionContext = createContext<ThemeActionContext>({} as ThemeActionContext);
export const ThemeActionConsumer = ThemeActionContext.Consumer;

interface ThemeProviderProps {
  children?: ReactNode;
}
const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
  const localeTheme = getItem('activeTheme') as ThemeVariant;
  const [currentTheme, setCurrentTheme] = useState<ThemeVariant>(localeTheme || 'light');

  const handleSetTheme = useCallback(
    (themeStr: ThemeVariant) => {
      setItem('activeTheme', themeStr);
      setCurrentTheme(themeStr);
    },
    [setCurrentTheme],
  );

  const setLightTheme = useCallback(() => {
    handleSetTheme('light');
  }, [handleSetTheme]);

  const setDarkTheme = useCallback(() => {
    handleSetTheme('dark');
  }, [handleSetTheme]);

  const switchTheme = useCallback(() => {
    if (currentTheme === 'light') {
      handleSetTheme('dark');
    } else {
      handleSetTheme('light');
    }
  }, [handleSetTheme, currentTheme]);

  const actions = useMemo(
    () => ({
      switchTheme,
      currentTheme,
      setLightTheme,
      setDarkTheme,
    }),
    [switchTheme, currentTheme, setLightTheme, setDarkTheme],
  );

  const theme: ThemeInterface = (() => (currentTheme === 'dark' ? darkTheme : lightTheme))();

  useEffect(() => {
    const htmlTag = document.getElementsByTagName('html')?.[0];
    if (htmlTag) {
      if (currentTheme === 'dark') {
        htmlTag.classList.add('dark');
      } else {
        htmlTag.classList.remove('dark');
      }
    }
  }, [currentTheme]);

  return (
    <ThemeActionContext.Provider value={actions}>
      <ReDesignThemeProvider theme={theme}>{children}</ReDesignThemeProvider>
    </ThemeActionContext.Provider>
  );
};

export default ThemeProvider;
