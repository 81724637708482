import { useLayoutEffect, useCallback, useRef, useState, MouseEvent } from 'react';
import Image from '@tiptap/extension-image';
import { Plugin } from 'prosemirror-state';
import { NodeViewProps, mergeAttributes, nodeInputRule } from '@tiptap/core';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';

const useEvent = <T extends (...args: any[]) => any>(handler: T): T => {
  const handlerRef = useRef<T | null>(null);

  useLayoutEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  return useCallback((...args: Parameters<T>): ReturnType<T> => {
    if (handlerRef.current === null) {
      throw new Error('Handler is not assigned');
    }
    return handlerRef.current(...args);
  }, []) as T;
};

const MIN_WIDTH = 60;

const ImageExtensionComponent = ({ node, updateAttributes }: NodeViewProps) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [resizingStyle, setResizingStyle] = useState<Pick<any, 'width'> | undefined>();

  const handleMouseDown = useEvent((event: MouseEvent) => {
    if (!imgRef.current) return;
    event.preventDefault();

    const initialXPosition = event.clientX;
    const currentWidth = imgRef.current.width;
    let newWidth = currentWidth;

    const removeListeners = () => {
      window.removeEventListener('mousemove', mouseMoveHandler);
      window.removeEventListener('mouseup', removeListeners);
      updateAttributes({ width: newWidth });
      setResizingStyle(undefined);
    };

    const mouseMoveHandler = (event: MouseEvent) => {
      newWidth = Math.max(currentWidth + (event.clientX - initialXPosition), MIN_WIDTH);
      setResizingStyle({ width: newWidth });

      // If mouse is up, remove event listeners
      if (!event.buttons) removeListeners();
    };

    window.addEventListener('mousemove', mouseMoveHandler);
    window.addEventListener('mouseup', removeListeners);
  });

  return (
    <NodeViewWrapper draggable data-drag-handle className="image-resizer">
      <img alt="Editor" {...node.attrs} ref={imgRef} style={resizingStyle} />

      <div role="button" tabIndex={0} onMouseDown={handleMouseDown} className="resize-trigger">
        <img src="/img/vector/resizeDown.svg" alt="Resize" width="42px" height="42px" />
      </div>
    </NodeViewWrapper>
  );
};

interface ImageOptions {
  HTMLAttributes: Record<string, any>;
}
declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    imageResize: {
      setImage: (options: {
        src: string;
        alt?: string;
        title?: string;
        width?: string | number;
        height?: string | number;
      }) => ReturnType;
    };
  }
}
const inputRegex = /(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))$/;
const ImageExtension = Image.extend<ImageOptions>({
  name: 'image',
  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },
  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          handlePaste: (_, e: ClipboardEvent) => {
            if (e.clipboardData?.files.length) {
              return false;
            }
          },
        },
      }),
    ];
  },
  addAttributes() {
    return {
      ...this.parent?.(),
      src: {
        renderHTML: attributes => {
          return {
            src: attributes.src,
          };
        },
      },
      width: {
        default: '100%',
        renderHTML: attributes => {
          return {
            width: attributes.width,
          };
        },
      },
      height: {
        default: 'auto',
        renderHTML: attributes => {
          return {
            height: attributes.height,
          };
        },
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'img',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ImageExtensionComponent);
  },
  addInputRules() {
    return [
      nodeInputRule({
        find: inputRegex,
        type: this.type,
        getAttributes: match => {
          const [, , alt, src, title, height, width] = match;
          return { src, alt, title, height, width };
        },
      }),
    ];
  },
});

export default ImageExtension;
