import { Button, icons } from 're-design';
import { SheetViewWrapper, SheetViewHeader, SheetViewBody } from './styled';
import { useHideHubspotChat } from '../../../lib/use-hubspot-chat';
import { ReactNode } from 'react';
import { FieldValues, UseFormReturn, useFormState, UseFormStateReturn } from 'react-hook-form';
import Flex from '../../quarks/Flex';
import Heading from '../../quarks/Heading';
import Responsive from '../../atoms/Responsive';
import AngleArrowLeftIcon from '../../quarks/icons/AngleArrowLeft';

interface SheetViewProps<FV extends FieldValues = FieldValues> {
  onClose?: () => void;
  onBack?: () => void;
  onCloseWithHookFormState?: (state: UseFormStateReturn<FV>) => void;
  headerText?: string;
  action?: ReactNode;
  expanded?: boolean;
  form?: UseFormReturn<FV>;
  hideOnMobile?: boolean;
  hideHeader?: boolean;
  variant?: string;
  size?: string;
  children?: ReactNode;
}

interface ButtonWithHookFormProps<FV extends FieldValues = FieldValues> {
  form: UseFormReturn<FV>;
  onClose?: (state: UseFormStateReturn<FV>) => void;
}
const ButtonWithHookForm = <FV extends FieldValues = FieldValues>({
  form,
  onClose,
}: ButtonWithHookFormProps<FV>) => {
  const { control } = form;
  const { isDirty } = useFormState({ control });

  return (
    <Button
      icon={<icons.CloseIcon />}
      onlyIcon
      variant="transparent"
      onClick={
        onClose
          ? () =>
              onClose({
                isDirty,
              } as UseFormStateReturn<FV>)
          : undefined
      }
    />
  );
};

const SheetRightPanel = <FV extends FieldValues = FieldValues>({
  onClose,
  onBack,
  onCloseWithHookFormState,
  children,
  headerText,
  action,
  expanded,
  form,
  hideOnMobile = false,
  hideHeader = false,
  variant,
  size,
}: SheetViewProps<FV>) => {
  useHideHubspotChat();

  return (
    <SheetViewWrapper expanded={expanded} hideOnMobile={hideOnMobile} variant={variant} size={size}>
      {!hideHeader && (
        <Responsive laptop tablet>
          <SheetViewHeader>
            <Flex between="small">
              {!!onBack && (
                <Button onClick={() => onBack()} onlyIcon icon={<AngleArrowLeftIcon />} />
              )}
              <Heading variant="h3">{headerText}</Heading>
            </Flex>
            <div>
              {action}

              {!!form && !!onCloseWithHookFormState ? (
                <ButtonWithHookForm form={form} onClose={onCloseWithHookFormState} />
              ) : (
                <>
                  {!!onClose && (
                    <Button
                      icon={<icons.CloseIcon />}
                      onlyIcon
                      variant="transparent"
                      onClick={onClose}
                    />
                  )}
                </>
              )}
            </div>
          </SheetViewHeader>
        </Responsive>
      )}
      <SheetViewBody hideHeader={hideHeader}>{children}</SheetViewBody>
    </SheetViewWrapper>
  );
};

export default SheetRightPanel;
