import { Route, Redirect } from 'react-router-dom';
import { PlanTypes } from '../../../../generated/graphql';
import url from '../../../../constants/url';
import { usePlanType } from '../../../../lib/use-me';

function Ppo3PlanRoute(props: any) {
  const companyPlanType = usePlanType();
  return companyPlanType === PlanTypes.Ppo3 ? <Route {...props} /> : <Redirect to={url.root} />;
}

export default Ppo3PlanRoute;
