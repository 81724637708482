import { FC, memo, ReactNode, ChangeEvent } from 'react';
import { BaseGearMenuItem } from './styled';

interface GearMenuItemProps {
  disabled?: boolean;
  warn?: boolean;
  onClick?(e: ChangeEvent): void;
  icon?: ReactNode;
  children?: ReactNode;
}
const GearMenuItem: FC<GearMenuItemProps> = memo(({ children, disabled, onClick, warn, icon }) => {
  return (
    <BaseGearMenuItem
      disabled={disabled}
      warn={warn}
      onClick={
        disabled || !onClick
          ? (e: ChangeEvent) => {
              e.preventDefault();
              e.stopPropagation();
              return;
            }
          : (e: ChangeEvent) => onClick(e)
      }
    >
      {icon ? <>{icon}</> : ''}
      <p>{children}</p>
    </BaseGearMenuItem>
  );
});

export default GearMenuItem;
