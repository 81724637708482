import { useContext, useState } from 'react';
import {
  LocalStorageActionContext,
  SetLocalStorageParams,
} from '../components/contexts/LocalStorage';

class LocalStorage {
  cache: Map<string, string> = new Map();

  setItem = (key: string, val: string) => {
    this.cache.set(key, val);
  };

  getItem = (key: string) => {
    return this.cache.get(key); // Added return statement
  };

  removeItem = (key: string) => {
    this.cache.delete(key);
  };
}

const storage = new LocalStorage();

export const setItem = (key: string, val: string) => {
  if (localStorage) {
    localStorage.setItem(key, val);
  } else {
    storage.setItem(key, val);
  }
};
export const getItem = (key: string) => {
  if (localStorage) {
    return localStorage.getItem(key);
  }

  return storage.getItem(key);
};

export const removeItem = (key: string) => {
  if (localStorage) {
    localStorage.removeItem(key);
  } else {
    storage.removeItem(key);
  }
};

export const useGetLocaleStorage = () => {
  const { getLocalStorage } = useContext(LocalStorageActionContext);
  return getLocalStorage;
};

export const useSetLocaleStorage = () => {
  const { setLocalStorage } = useContext(LocalStorageActionContext);
  return setLocalStorage;
};

export const useLocaleStorage = <T extends Partial<T>>(
  _localStorageKey: string,
  defaultValue: any,
  userId?: string | null,
  params?: SetLocalStorageParams,
): [T, (_value: T) => void] => {
  const { getLocalStorage, setLocalStorage } = useContext(LocalStorageActionContext);

  const localStorageKey = userId ? `${_localStorageKey}-${userId}` : _localStorageKey;
  const lsValue = getLocalStorage(localStorageKey, defaultValue) as T;

  const handleSetValue = (_value: T) => {
    setLocalStorage(localStorageKey, _value, params || {});
  };
  return [lsValue, handleSetValue];
};

export const useLocaleStorageState = <T extends Partial<T>>(
  _localStorageKey: string,
  defaultValue: any,
  userId?: string,
  params?: SetLocalStorageParams,
): [T, (_value: T) => void] => {
  const { getLocalStorage, setLocalStorage } = useContext(LocalStorageActionContext);

  const localStorageKey = userId ? `${_localStorageKey}-${userId}` : _localStorageKey;
  const lsValue = getLocalStorage(localStorageKey, defaultValue) as T;
  const [value, setValue] = useState<T>(lsValue);

  const handleSetValue = (_value: T) => {
    setLocalStorage(localStorageKey, _value, params || {});
    setValue(_value);
  };
  return [value, handleSetValue];
};
