import { FC } from 'react';
import { CommonIconProps, defaultPathClassName } from './utils';

const AttentionIcon: FC<CommonIconProps> = ({
  size = '100%',
  className,
  pathClassName = defaultPathClassName,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      className={pathClassName}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.25 8C1.25 4.27833 4.27833 1.25 8 1.25C11.7217 1.25 14.75 4.27833 14.75 8C14.75 11.7217 11.7217 14.75 8 14.75C4.27833 14.75 1.25 11.7217 1.25 8ZM2.25 8C2.25 11.1708 4.82917 13.75 8 13.75C11.1708 13.75 13.75 11.1708 13.75 8C13.75 4.82917 11.1708 2.25 8 2.25C4.82917 2.25 2.25 4.82917 2.25 8ZM8 9.33333C8.27583 9.33333 8.5 9.10917 8.5 8.83333V4.25C8.5 3.97417 8.27583 3.75 8 3.75C7.72417 3.75 7.5 3.97417 7.5 4.25V8.83333C7.5 9.10917 7.72417 9.33333 8 9.33333ZM8.00002 10C7.60919 10 7.29169 10.3175 7.29169 10.7083C7.29169 11.0992 7.60919 11.4167 8.00002 11.4167C8.39085 11.4167 8.70835 11.0992 8.70835 10.7083C8.70835 10.3175 8.39085 10 8.00002 10Z"
    />
  </svg>
);

export default AttentionIcon;
