import { Suspense, useContext, FC } from 'react';
import {
  findDescriptedComponentName,
  getSideSheetsMap,
  SideSheetKey,
  SIDESHEET_UUID,
} from '../../../lib/sidesheet';
import { useRouter } from '../../../lib/use-router';
import { hasElement, takeAwayEmptyElement } from '../../../utils/array';
import { getKeyByValue } from '../../../utils/object';
import { SideSheetState, SideSheetStateContext } from '../../contexts/SideSheetContext';
import { SideSheetVariant } from '../SideSheet';

const _getSideSheetsFromQuery = (
  query: any,
  _contextSideSheets: SideSheetState[],
): SideSheetState[] => {
  const sideSheets: SideSheetState[] = [];

  if (!query.sd) {
    return [];
  }

  const contextSideSheets = takeAwayEmptyElement<SideSheetState[]>(_contextSideSheets);
  const currentContextSideSheets = contextSideSheets[contextSideSheets.length - 1];

  try {
    const modalDecryptedKey = JSON.parse(window.atob(query.sd!));
    const isFullscreen = query?.w === '1';

    const isQueryAndContextSame = (() => {
      if (
        currentContextSideSheets?.sideSheetType?.displayName !==
        getKeyByValue(SIDESHEET_UUID, modalDecryptedKey?.c)
      ) {
        return false;
      }

      switch (modalDecryptedKey?.c) {
        case SIDESHEET_UUID[SideSheetKey.ObjectiveDetail]:
          return modalDecryptedKey.p.id === currentContextSideSheets?.sideSheetProps?.objectiveId;
        case SIDESHEET_UUID[SideSheetKey.KeyResultDetail]:
          return modalDecryptedKey.p.id === currentContextSideSheets?.sideSheetProps?.keyResultId;
        case SIDESHEET_UUID[SideSheetKey.KeyMetricDetail]:
          return modalDecryptedKey.p.id === currentContextSideSheets?.sideSheetProps?.keyMetricId;
        case SIDESHEET_UUID[SideSheetKey.Member]:
          return modalDecryptedKey.p.id === currentContextSideSheets?.sideSheetProps?.memberId;
        case SIDESHEET_UUID[SideSheetKey.Team]:
          return modalDecryptedKey.p.id === currentContextSideSheets?.sideSheetProps?.teamId;
        default:
          return false;
      }
    })();

    if (isQueryAndContextSame) {
      return sideSheets;
    }

    const sideSheetsMap = getSideSheetsMap();
    const descriptedC = findDescriptedComponentName(modalDecryptedKey?.c!);
    const sideSheetTypeFunc = descriptedC ? (sideSheetsMap as any)[descriptedC] : null;
    switch (modalDecryptedKey?.c) {
      case SIDESHEET_UUID[SideSheetKey.ObjectiveDetail]:
        sideSheets.push({
          sideSheetType: sideSheetTypeFunc?.(),
          sideSheetProps: {
            objectiveId: modalDecryptedKey.p.id,
            sideSheetVariant: isFullscreen ? SideSheetVariant.Fullscreen : undefined,
          },
        });
        break;

      case SIDESHEET_UUID[SideSheetKey.KeyResultDetail]:
        sideSheets.push({
          sideSheetType: sideSheetTypeFunc?.(),
          sideSheetProps: {
            keyResultId: modalDecryptedKey.p.id,
            sideSheetVariant: isFullscreen ? SideSheetVariant.Fullscreen : undefined,
          },
        });
        break;

      case SIDESHEET_UUID[SideSheetKey.KeyMetricDetail]:
        sideSheets.push({
          sideSheetType: sideSheetTypeFunc?.(),
          sideSheetProps: {
            keyMetricId: modalDecryptedKey.p.id,
            sideSheetVariant: isFullscreen ? SideSheetVariant.Fullscreen : undefined,
          },
        });
        break;
      case SIDESHEET_UUID[SideSheetKey.Member]:
        sideSheets.push({
          sideSheetType: sideSheetTypeFunc?.(),
          sideSheetProps: {
            memberId: modalDecryptedKey.p.id,
          },
        });
        break;
      case SIDESHEET_UUID[SideSheetKey.Team]:
        sideSheets.push({
          sideSheetType: sideSheetTypeFunc?.(),
          sideSheetProps: {
            teamId: modalDecryptedKey.p.id,
          },
        });
        break;
    }

    return sideSheets;
  } catch {
    return [];
  }
};

const SideSheetManager: FC = () => {
  const { query } = useRouter();
  const contextSideSheets = useContext(SideSheetStateContext);
  const querySideSheets = _getSideSheetsFromQuery(query, contextSideSheets);

  const sideSheets = [
    ...takeAwayEmptyElement(contextSideSheets),
    ...takeAwayEmptyElement(querySideSheets),
  ];

  if (!hasElement(sideSheets)) return null;

  const current = sideSheets[sideSheets.length - 1];
  if (!current || !current.sideSheetType) return null;

  const Component = current.sideSheetType;
  return (
    <Suspense fallback="">
      <Component {...current.sideSheetProps} />
    </Suspense>
  );
};

export default SideSheetManager;
