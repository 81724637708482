import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { User, CompanySubscription } from '../../../generated/graphql';
import { isCompanySubscribedWithCard } from '../../../utils/payment';
import { hasOwnerRole } from '../../../utils/role';
import WarningBar from '../../atoms/WarningBar';
import url from '../../../constants/url';

interface UnpaidBarProps {
  user: User;
  subscription: CompanySubscription;
}

const UnpaidBar: FC<UnpaidBarProps> = ({ user, subscription }) => {
  const { t } = useTranslation();
  const isSubscribedWithCard = isCompanySubscribedWithCard(subscription);
  const isOwner = hasOwnerRole(user);

  const jsxNextStepMessage = isSubscribedWithCard ? (
    <>
      「<em>{t('利用再開画面')}</em>」{t('にてお支払い手続きを完了してください。')}
    </>
  ) : (
    t('郵送されている請求書を確認いただき、お支払い手続きを完了してください。')
  );

  return (
    <WarningBar
      variant="warn"
      url={isSubscribedWithCard && isOwner ? url.settings.company.billing.index : undefined}
    >
      {t('未払いの決済があります。')}
      {isOwner ? (
        <span>{jsxNextStepMessage}</span>
      ) : (
        <span>{t('管理者に連絡してお支払い手続きを完了してください。')}</span>
      )}
    </WarningBar>
  );
};

export default UnpaidBar;
