import { memo, useMemo, FC, useCallback, ReactNode, createContext, useReducer } from 'react';
import { ModalKey } from '../../lib/modal';

interface ModalLayer {
  key: string;
  modal: {
    modalType: null | FC<any>;
    modalProps?: any;
  };
}

interface ModalState {
  layers: ModalLayer[];
}

type ModalAction = {
  type: 'OPEN_MODAL' | 'CLOSE_MODAL';
  payload?: any;
  key: string;
};

interface ModalActionContext {
  openModal<T>(modalType: FC<T> | ModalKey, modalProps?: T): void;
  openModalWithKey<T>(modalType: FC<T> | ModalKey, modalKey: string, modalProps?: T): void;
  closeModal: () => void;
  closeAllModal: () => void;
  closeModalWithKey: (modalKey: string) => void;
  getIsModalOpened: () => boolean;
}

export const ModalStateContext = createContext<ModalState>({} as ModalState);
export const ModalStateConsumer = ModalStateContext.Consumer;
export const ModalActionContext = createContext<ModalActionContext>({} as ModalActionContext);
export const ModalActionConsumer = ModalActionContext.Consumer;

const ModalReducer = (state: ModalState, action: ModalAction) => {
  const { type, key = 'default' } = action;

  const layers = [...state.layers];
  const index = layers.findIndex(l => l.key === key);

  switch (type) {
    case 'OPEN_MODAL': {
      const { modalType, modalProps } = action.payload || {};

      if (index > -1) {
        layers[index] = {
          key,
          modal: {
            modalType,
            modalProps,
          },
        };
      } else {
        layers.push({
          key,
          modal: {
            modalType,
            modalProps,
          },
        });
      }

      return {
        layers,
      };
    }

    case 'CLOSE_MODAL': {
      if (index > -1) {
        layers.splice(index, 1);
      }

      return {
        layers,
      };
    }
  }
};

interface ModalProviderProps {
  children?: ReactNode;
}
const ModalProvider: FC<ModalProviderProps> = memo(({ children }) => {
  const [state, dispatch] = useReducer(ModalReducer, {
    layers: [] as ModalLayer[],
  });

  const openModal = useCallback((modalType: FC<any>, modalProps?: any) => {
    dispatch({
      type: 'OPEN_MODAL',
      key: 'default',
      payload: {
        modalType,
        modalProps,
      },
    });
  }, []);

  const closeModal = useCallback(() => {
    dispatch({
      type: 'CLOSE_MODAL',
      key: 'default',
    });
  }, []);

  const closeAllModal = useCallback(() => {
    dispatch({
      type: 'CLOSE_MODAL',
      key: 'default',
    });
  }, []);

  const openModalWithKey = useCallback((modalType: FC<any>, modalKey: string, modalProps?: any) => {
    dispatch({
      type: 'OPEN_MODAL',
      key: modalKey,
      payload: {
        modalType,
        modalProps,
      },
    });
  }, []);

  const closeModalWithKey = useCallback((modalKey: string) => {
    dispatch({
      type: 'CLOSE_MODAL',
      key: modalKey,
    });
  }, []);

  const getIsModalOpened = useCallback(() => {
    return state.layers?.length > 0;
  }, [state]);

  const actions = useMemo(
    () => ({
      openModal,
      closeModal,
      closeAllModal,
      openModalWithKey,
      closeModalWithKey,
      getIsModalOpened,
    }),
    [openModal, closeModal, openModalWithKey, closeModalWithKey, getIsModalOpened],
  );

  return (
    <ModalStateContext.Provider value={state}>
      <ModalActionContext.Provider value={actions}>{children}</ModalActionContext.Provider>
    </ModalStateContext.Provider>
  );
});

export default ModalProvider;
