import { Suspense, memo, useContext, FC } from 'react';
import { ModalStateContext } from '../../contexts/ModalContext';

const ModalManager: FC = memo(() => {
  const modal = useContext(ModalStateContext);

  const layers = modal.layers;

  if (!layers.length) {
    return null;
  }

  return (
    <>
      {layers.map((layer, index) => {
        const Component = layer.modal.modalType;
        return Component ? (
          <Suspense fallback="" key={`modal-${index}`}>
            <Component {...layer.modal.modalProps} />
          </Suspense>
        ) : null;
      })}
    </>
  );
});

export default ModalManager;
