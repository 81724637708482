import styled, { css } from 'styled-components';

export const PopperTarget = styled.div`
  position: relative;
  vertical-align: middle;

  ${(props: { display?: string }) => css`
    display: ${props.display || 'inline-block'};
  `}
`;

interface PopperWrapProps {
  zIndex?: number;
  context?: 'stickyRight';
}

export const PopperWrap = styled.div`
  z-index: ${(props: PopperWrapProps) => (props.zIndex ? props.zIndex + 1 : 'var(--index-popper)')};

  ${(props: PopperWrapProps) =>
    props.context === 'stickyRight' &&
    css`
      left: var(--spacing-6-inverse) !important;
    `}
`;

interface BasePopperProps {
  variant?: string;
}
export const BasePopper = styled.div`
  position: relative;
  margin: var(--spacing-4);
  padding: var(--spacing-3) 0;
  background-color: var(--ui-01);
  border-radius: var(--radius-small);
  box-shadow: 0 2px 10px 0 var(--shadow-01);
  z-index: 1;
  overflow: hidden;

  ${(props: BasePopperProps) =>
    props.variant?.includes('noborder') &&
    css`
      box-shadow: none;
    `}

  ${(props: BasePopperProps) =>
    props.variant?.includes('nopadding') &&
    css`
      padding: 0;
    `}

  ${(props: BasePopperProps) =>
    props.variant?.includes('selectPeriod') &&
    css`
      padding: 0;
      margin: 0;
      margin-top: var(--spacing-4);
    `}
`;

interface PopperArrowProps {
  placement?: string;
}

export const PopperArrow = styled.div`
  position: absolute;
  width: 18px;
  height: 24px;
  overflow: hidden;
  z-index: 5;

  :before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    background: var(--ui-01);
    box-shadow: 2px -2px 10px 0 rgba(0, 0, 0, 0.12);
    display: none;
  }

  ${(props: PopperArrowProps) =>
    !!props.placement &&
    css`
      ${props.placement === 'left' &&
      css`
        right: -6px;
        top: 24px;

        :before {
          margin-top: 6px;
          margin-left: -6px;
          display: block;
        }
      `};

      ${props.placement === 'right' &&
      css`
        left: -6px;
        top: 24px;

        :before {
          margin-top: 6px;
          margin-left: var(--spacing-4);
          transform: rotate(-135deg);
          display: block;
        }
      `};

      ${props.placement === 'top' &&
      css`
        bottom: -6px;
        width: 24px;
        height: 18px;

        :before {
          margin-top: -6px;
          margin-left: 6px;
          display: block;
        }
      `};

      ${props.placement === 'bottom' &&
      css`
        top: -6px;
        width: 24px;
        height: 18px;

        :before {
          margin-top: 12px;
          margin-left: 6px;
          transform: rotate(135deg);
          display: block;
        }
      `};
    `};
`;
